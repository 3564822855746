/* eslint-disable no-console */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { trackAll } from 'analytics/tracking';

import secureforms from 'secureForms';

import { STATES, PROVINCES, COUNTRIES, CURRENCIES } from 'lib/constants/locations';

import { paymentMethodSetup, paymentMethodSubmit } from 'state/secureFormsPaymentMethods/actions';

import { loansSelector } from 'state/loans/selectors';
import {
  secureFormsPaymentMethodsSubmittingSelector,
  secureFormsPaymentMethodsErrorSelector,
} from 'state/secureFormsPaymentMethods/selectors';
import { tokenizeSelector } from 'state/paymentMethods/selectors';
import { isCanadianSelector, countrySelector } from 'state/account/selectors';
import { selectedPaymentMethod } from 'state/paymentMethods/actions';

import { MaterialIcon } from 'styles/Icons';
import {
  Button,
  ButtonOutline,
  Col,
  Details,
  Detail,
  ErrorMessage,
  Label2,
  Row,
  StyledInput,
  StyledSelect,
} from 'styles/CommonStyles';
import Loader from 'components/Loader';
import SecureFormCardInput from './components/SecureFormCardInput';
import SecureFormInput from './components/SecureFormInput';

const DetailsExt = styled(Details)`
  padding: 1rem;
  margin: 0.5rem auto;
  max-width: 425px;
`;

const Form = styled.form`
  width: 100%;
`;

const SubHeader = styled.div`
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem 0.5rem 0.4rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const ButtonGroup = styled.div`
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
  padding: 0.5rem 1rem;
`;

const ErrorPanel = styled.div`
  background-color: #f2dede;
  color: #a94442;

  border: 1px solid transparent;
  border-color: #ebccd1;
  border-radius: 4px;

  margin: 2rem 0 0 0;
  padding: 1rem;
`;

const MiniErrorPanel = styled.div`
  background-color: rgba(255, 0, 0, 0.1);
  color: red;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  font-size: 0.9rem;
  font-weight: bold;

  border-radius: 6px;

  margin: 1.5rem 0rem;
  padding: 1rem;
`;

const iconColor = 'red';
const iconSize = '1.2rem';

// prettier-ignore
const PaymentMethodsForm = memo(React.forwardRef((props, ref) => { // NOSONAR
  const { id, cancelPaymentForm, paymentMethodType, setShowAlertSuccess } = props;
  const { t } = useTranslation();

  const { register, handleSubmit, errors, triggerValidation } = useForm();

  const displayPaymentErrorMessage = useSelector(secureFormsPaymentMethodsErrorSelector);
  const submitting = useSelector(secureFormsPaymentMethodsSubmittingSelector);

  const dispatch = useDispatch();

  const { tokenize } = useSelector(tokenizeSelector);
  const loans = useSelector(loansSelector);

  const country = useSelector(countrySelector);
  const currency = CURRENCIES[country];

  const isCanadian = useSelector(isCanadianSelector);

  const [selectedCountry, setSelectedCountry] = useState(isCanadian ? 'CA' : 'US');

  const isCanada = selectedCountry === 'CA';

  const regionDropdown = isCanada ? PROVINCES : STATES;

  const countryChanged = useCallback((event) => {
    // eslint-disable-next-line no-unused-expressions
    event.target.value === 'CA' ? setSelectedCountry('CA') : setSelectedCountry('US');
  }, []);

  useEffect(() => {
    if (tokenize) {
      dispatch(paymentMethodSetup(tokenize, setShowAlertSuccess, selectedCountry));
    }
  }, [dispatch, selectedCountry, setShowAlertSuccess, tokenize]);

  const onSubmit = useCallback(
    (data) => {
      const str = data.street.toString()
      const sanitizedStreet = str.replace(/[^\x20-\xFF]/g, "");
      const sanitizedCurrency = (loans && loans.length > 0 && loans[0].currency) || currency || '';

      // (defn ^:export isValid [payment-type type]
      const isCardValid = secureforms.isValid('card', 'number');
      const isCvvValid = secureforms.isValid('card', 'ccv');
      const isExpirationValid = secureforms.isValid('card', 'expiration');
      const isAddressValid = secureforms.isValid('card', 'zip');

      if (!isCardValid || !isCvvValid || !isExpirationValid || !isAddressValid) {
        // secureForms validation error
        return;
      }

      trackAll({
        category: 'user',
        action: 'click',
        label: 'submit-add-credit-card',
        value: 1,
      });

      // Set {"currency": "CURRENCY_TYPE"}  Values: CAD | USD for secure-forms 'pay'
      // eslint-disable-next-line no-param-reassign
      data = { ...data, street: sanitizedStreet, currency: sanitizedCurrency.toUpperCase() };
      console.log('PaymentMethodsForm: onSubmit: data for pay: ', data);

      // (defn ^:export pay [payment-type order-data]
      dispatch(selectedPaymentMethod(paymentMethodType));
      dispatch(paymentMethodSubmit(data));
    },
    [currency, dispatch, loans, paymentMethodType]
  );

  const onCancelFormPayment = useCallback(() => {
    trackAll({
      category: 'user',
      action: 'click',
      label: 'close-edit-credit-card',
      value: 1,
    });

    cancelPaymentForm(id);
  }, [cancelPaymentForm, id]);

  const paymentErrorMessage = displayPaymentErrorMessage && displayPaymentErrorMessage.error === 'Secure Form Failure'
  ? t('account.payment_methods.details.results.card_declined')
  : t('account.payment_methods.details.results.card_error')

  return (
    <div ref={ref}>
      {displayPaymentErrorMessage ? (
        <ErrorPanel>
          <div>
            {paymentErrorMessage}
          </div>
        </ErrorPanel>
      ) : null}

      <DetailsExt>
        <Detail>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="d-flex align-items-center">
                <MaterialIcon name="credit_card" size={iconSize} />
                <SubHeader data-testid="card-header">{t('account.payment_methods.form.card')}</SubHeader>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label2 data-testid="name-on-card">{t('account.payment_methods.form.name_on_card')} *</Label2>
                  <StyledInput
                    data-testid="name-on-card-input"
                    error={errors.name}
                    name="name"
                    type="text"
                    placeholder={t('account.payment_methods.form.name_on_card')}
                    ref={register({ required: 'account.payment_methods.errors.as_appears_card' })}
                  />
                  {errors.name && <ErrorMessage>{t(errors.name.message)}</ErrorMessage>}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label2 data-testid="card-number" htmlFor="card_info_number">
                    {t('account.payment_methods.form.card_number')} *
                  </Label2>

                  <SecureFormCardInput
                    register={register}
                    error={errors.card_info_number}
                    triggerValidation={triggerValidation}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label2 data-testid="card-info-expiration" htmlFor="card_info_expiration">
                    {t('account.payment_methods.form.expiration')} *
                  </Label2>

                  <SecureFormInput
                    id="card_info_expiration"
                    field="expiration"
                    error={errors.card_info_expiration}
                    errorMessage="account.payment_methods.errors.invalid_date"
                    register={register}
                    triggerValidation={triggerValidation}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label2 data-testid="card-info-ccv" htmlFor="card_info_ccv">
                    {t('account.payment_methods.form.cvv')} *
                  </Label2>

                  <SecureFormInput
                    id="card_info_ccv"
                    field="ccv"
                    error={errors.card_info_ccv}
                    errorMessage="account.payment_methods.errors.invalid_code"
                    register={register}
                    triggerValidation={triggerValidation}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <Row>
              
              <Col className="d-flex align-items-center">
                <MaterialIcon name="article" size={iconSize} />
                <SubHeader data-testid="billing-address-header">{t('account.payment_methods.form.billing_address')}</SubHeader>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label2 data-testid="country" for="country-dropdown">{t('account.payment_methods.form.country')} *</Label2>
                  <StyledSelect
                    id="country-dropdown"
                    error={errors.country}
                    name="country"
                    defaultValue={selectedCountry}
                    onChange={countryChanged}
                    disabled
                    ref={register({ required: 'account.payment_methods.errors.country_required' })}
                  >
                    {Object.keys(COUNTRIES).map((key) => {
                      return (
                        <option value={key} key={key}>
                          {COUNTRIES[key]}
                        </option>
                      );
                    })}
                  </StyledSelect>
                  {errors.country && <ErrorMessage>{t(errors.country.message)}</ErrorMessage>}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label2 data-testid="street-address">{t('account.payment_methods.form.street')} *</Label2>
                  <StyledInput
                    data-testid="street-address-input"
                    error={errors.street}
                    maxLength={255}
                    name="street"
                    type="text"
                    placeholder={t('account.payment_methods.form.street')}
                    ref={register({ required: 'account.payment_methods.errors.street_address_required' })}
                  />
                  {errors.street && <ErrorMessage>{t(errors.street.message)}</ErrorMessage>}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label2 data-testid="city">{t('account.payment_methods.form.city')} *</Label2>
                  <StyledInput
                    data-testid="city-input"
                    error={errors.city}
                    name="city"
                    type="text"
                    placeholder={t('account.payment_methods.form.city')}
                    ref={register({ required: 'account.payment_methods.errors.city_required' })}
                  />
                  {errors.city && <ErrorMessage>{t(errors.city.message)}</ErrorMessage>}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label2 data-testid={`${isCanada ? 'province' : 'region'}`}>
                    {t(`account.payment_methods.form.${isCanada ? 'province' : 'region'}`)} *
                  </Label2>
                  <StyledSelect
                    error={errors.state}
                    name="state"
                    defaultValue=""
                    ref={register({
                      required: `account.payment_methods.errors.${isCanada ? 'province' : 'region'}_required`,
                    })}
                  >
                    <option value="" disabled>
                      {t(`account.payment_methods.form.${isCanada ? 'province' : 'region'}`)}
                    </option>
                    {Object.keys(regionDropdown).map((key) => {
                      return (
                        <option value={key} key={key}>
                          {regionDropdown[key]}
                        </option>
                      );
                    })}
                  </StyledSelect>
                  {errors.state && <ErrorMessage>{t(errors.state.message)}</ErrorMessage>}
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label2 data-testid={`${isCanada ? 'postal-code' : 'zip'}`} htmlFor="billing_zip">
                    {t(`account.payment_methods.form.${isCanada ? 'postal_code' : 'zip'}`)} *
                  </Label2>
                  <SecureFormInput
                    id="billing_zip"
                    field="zip"
                    error={errors.billing_zip}
                    errorMessage={`account.payment_methods.form.${isCanada ? 'postal_code' : 'zip'}_invalid`}
                    register={register}
                    triggerValidation={triggerValidation}
                  />
                </FormGroup>
              </Col>
            </Row>

            {displayPaymentErrorMessage ? (
              <MiniErrorPanel>
                <MaterialIcon name="error_outline" size={iconSize} color={iconColor} />
                &nbsp;
                <div>
                  {paymentErrorMessage}
                </div>
              </MiniErrorPanel>
            ) : null}

            <Row>
              <ButtonGroup>
                {submitting ? (
                  <Loader />
                ) : (
                  <>
                    <ButtonOutline
                      data-testid="modal-button-2"
                      variant="outline-primary"
                      className="mr-1"
                      onClick={onCancelFormPayment}
                    >
                      {t('common.buttons.cancel')}
                    </ButtonOutline>

                    <Button data-testid="update-button" type="submit">
                      {t('account.payment_methods.form.add_card')}
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Row>
          </Form>
        </Detail>
      </DetailsExt>
    </div>
  );
}));

PaymentMethodsForm.displayName = 'PaymentMethodsForm';

PaymentMethodsForm.propTypes = {
  id: PropTypes.string.isRequired,
  cancelPaymentForm: PropTypes.func.isRequired,
  paymentMethodType: PropTypes.string.isRequired,
  setShowAlertSuccess: PropTypes.func,
};

PaymentMethodsForm.defaultProps = {
  setShowAlertSuccess: null,
};

export default PaymentMethodsForm;
