import React, { memo, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { printDocument, toKebabCase } from 'lib/utils';
import { documentsSelector } from 'state/documents/selectors';

import trackEvent from 'analytics/trackEvent';
import * as cevents from 'up.lib.js.cevents';

import { MaterialIcon } from 'styles/Icons';
import { ButtonLink, IconColorHyperlinkBlue } from 'styles/CommonStyles';
import Loader from 'components/Loader';

const StyledLink = styled(NavLink)`
  display: flex;

  color: ${(props) => props.theme.colors.hyperlinkBlue};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.hyperlinkBlueActive};
    text-decoration: none;
  }
`;

const SubHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 1rem 3rem;
`;

const Document = styled.div``;

const IframeDocument = styled.iframe`
  border: 0;
  width: 100%;
  height: 65vh;
`;

const iconSize1 = '1.5rem';
const iconSize2 = '1.2rem';

const DocumentDetailsView = memo(({ returnTo, docUrl }) => {
  const { t } = useTranslation();
  const documents = useSelector(documentsSelector);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const checkDocumentExpiry = async () => {
      const urlParams = new URLSearchParams(docUrl?.split('?')[1]);
      const expires = urlParams.get('Expires');
      const currentTime = Math.floor(Date.now() / 1000);

      if (expires && currentTime > parseInt(expires, 10)) {
        setIsExpired(true);
      }
    };
    checkDocumentExpiry();
  }, [docUrl, documents]);

  const onPrint = useCallback(async () => {
    if (docUrl) {
      // google analytics
      const gaEvent = {
        category: 'user',
        action: 'click',
        label: 'print-document',
        value: 1,
      };
      trackEvent(gaEvent);

      // cevents
      let metadata = {};
      metadata.event_label = docUrl;
      metadata.uplift_account_id = localStorage.getItem('account_id_hash');
      metadata.isAdmin = localStorage.getItem('adminAuth');
      metadata = toKebabCase(metadata);

      cevents.setLevel('debug');
      cevents.debugRaw(gaEvent.category, gaEvent.action, gaEvent.label, metadata);

      printDocument(docUrl);
    }
  }, [docUrl]);

  return docUrl ? (
    <>
      <SubHeader>
        <StyledLink to={`/${returnTo}`}>
          <MaterialIcon name="chevron_left" size={iconSize1} color={IconColorHyperlinkBlue} />
          <div>{t(`header.navigation.${returnTo}`)}</div>
        </StyledLink>
        <ButtonLink onClick={onPrint}>
          <div>{t('common.buttons.print')}</div>&nbsp;
          <MaterialIcon name="print" size={iconSize2} color={IconColorHyperlinkBlue} />
        </ButtonLink>
      </SubHeader>

      {!isExpired ? (
        <IframeDocument title="document" src={docUrl} />
      ) : (
        <Document>
          <div className="container">{t('documents.expired')}</div>
        </Document>
      )}
    </>
  ) : (
    <div>
      <Loader />
    </div>
  );
});

DocumentDetailsView.displayName = 'DocumentDetailsView';

DocumentDetailsView.propTypes = {
  docUrl: PropTypes.node,
  returnTo: PropTypes.string.isRequired,
};

DocumentDetailsView.defaultProps = {
  docUrl: null,
};

export default DocumentDetailsView;
