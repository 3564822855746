/* eslint-disable react/prop-types */
import React, { memo, useEffect, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import secureforms from 'secureForms';
import * as cevents from 'up.lib.js.cevents';

import { secureFormsPaymentMethodsBlsSelector } from 'state/secureFormsPaymentMethods/selectors';

import { ErrorMessage } from 'styles/CommonStyles';
import { StyledInputDiv } from './SecureFormInput';

const SecureFormCardInput = memo(({ register, error: cardError, triggerValidation }) => {
  const { t } = useTranslation();

  const { valid, code, cardType, cardValid } = useSelector(secureFormsPaymentMethodsBlsSelector) || {};

  const validationRef = useRef({ name: 'card_info_number' });
  useEffect(() => {
    const secondaryMount = 'code' in validationRef.current;
    Object.assign(validationRef.current, { valid, code, cardType, cardValid });
    if (secondaryMount) {
      triggerValidation({ name: 'card_info_number' });
    }
  }, [triggerValidation, valid, code, cardType, cardValid]);

  const error = cardError && cardError.message;

  register(validationRef.current, {
    // eslint-disable-next-line no-unused-vars
    validate: (value) => {
      // check for bls code from redux state
      // eslint-disable-next-line no-shadow
      const { cardType } = validationRef.current; // NOSONAR
      if (cardType === null) {
        // empty field
        return 'account.payment_methods.errors.invalid_card_number';
      }

      const isCardValid = secureforms.isValid('card', 'number');

      // cevents
      const metadata = {};
      metadata.cardType = cardType;
      metadata.isCardValid = isCardValid;

      cevents.setLevel('debug');
      cevents.debugRaw('user', 'keypress', 'check-add-credit-card', metadata);

      if (isCardValid) {
        // complete and valid
        return true;
      }

      // fall back error
      return 'account.payment_methods.errors.invalid_card_number';
    },
  });

  const [focus, setFocus] = useState(false);
  const onBlur = useCallback(() => setFocus(false), []);
  const onFocus = useCallback(() => setFocus(true), []);

  return (
    <>
      <StyledInputDiv id="card_info_number" error={error} focus={focus} onBlur={onBlur} onFocus={onFocus} />
      {cardError && <ErrorMessage>{t(error)}</ErrorMessage>}
    </>
  );
});

SecureFormCardInput.displayName = 'SecureFormCardInput';

SecureFormCardInput.propTypes = {
  register: PropTypes.func.isRequired,
  error: PropTypes.shape({
    cardError: PropTypes.objectOf(PropTypes.string),
  }),
  triggerValidation: PropTypes.func.isRequired,
};

SecureFormCardInput.defaultProps = {
  error: null,
};

export default SecureFormCardInput;
